import React, { useMemo, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useQuery } from "react-query";
import { Route, Routes } from "react-router-dom";
import Map from "./components/Map";
import Nav from "./components/Navigation/Nav";
import { YearContext } from "./context/YearContext";
import PageNotFound from "./pages/404";
import MDS from "./pages/About/MDS";
import Methodology from "./pages/About/Methodology";
import Scorecard from "./pages/About/Scorecard";
import Contact from "./pages/Contact";
import Country from "./pages/Country";
import Error from "./pages/Error";
import Reports from "./pages/Reports";
function App() {
  const [year, setYear] = useState(2022);
  const [filter, setFilter] = useState("all");
  const [countries, setCountries] =
    useState(null);
  const [availableYears, setAvailableYears] =
    useState(null);
  // eslint-disable-next-line no-unused-vars
  const { isError } = useQuery(
    "availableYears",
    async () => {
      const req = await fetch(
        "/.netlify/functions/getYears"
      );
      const res = await req.json();
      return res;
    },
    {
      onSuccess: (data) =>
        setAvailableYears(data),
    }
  );
  const { isError: error } = useQuery(
    `country-${year}`,
    async () => {
      const res = await fetch(
        `/.netlify/functions/getCountries?year=${availableYears[0]}`
      );
      if (res.ok) {
        return res.json();
      }
      throw new Error("Something went wrong...");
    },
    {
      staleTime: 5 * 60000, // 10 seconds
      enabled: !!availableYears,
      onSuccess: (data) => {
        const countries = data.map((country) => ({
          id: country.id,
          country_name: country.fields.Country,
          country_main_scores: {
            digital_economy_score: Math.floor(
              country.fields[
                "Digital Economy Score"
              ] * 100
            ),
            digital_inclusiveness_score:
              Math.floor(
                country.fields[
                  "Digital Inclusiveness Score"
                ] * 100
              ),
            gender_inclusiveness_score:
              Math.floor(
                country.fields[
                  "Gender Inclusiveness Score"
                ] * 100
              ),
          },
          country_geo: country.fields.geo[0].url,
          country_status: country.fields.Status,
          country_flag:
            country.fields.Flag[0].url,
          country_code:
            country.fields["CountryCode"],
        }));

        setCountries(countries);
      },
      notifyOnChangeProps: ["data"],
      refetchOnWindowFocus: false,
    }
  );
  const value = useMemo(
    () => ({
      year,
      setYear,
      filter,
      setFilter,
      countries,
      setCountries,
      availableYears,
    }),
    [year, filter, countries, availableYears]
  );

  if (isError || error) {
    return (
      <div className="App h-auto flex flex-col w-auto">
        <header>
          <Nav />
        </header>
        <div className="w-full h-full">
          <Error />
        </div>
      </div>
    );
  }

  return (
    <ErrorBoundary FallbackComponent={Error}>
      <YearContext.Provider value={value}>
        <div className="App h-auto flex flex-col w-auto">
          <header>
            <Nav />
          </header>
          <div className="w-full h-full ">
            <Routes>
              <Route path="/" element={<Map />} />
              <Route
                path="/dashboard"
                element={<Map />}
              />
              <Route
                path="/:year/:country"
                element={<Country />}
              ></Route>
              <Route
                path="about/scorecard"
                element={<Scorecard />}
              ></Route>
              <Route
                path="about/methodology"
                element={<Methodology />}
              ></Route>
              <Route
                path="about/market-development-stages"
                element={<MDS />}
              ></Route>
              <Route
                path="/contact"
                element={<Contact />}
              />
              <Route
                path="/reports"
                element={<Reports />}
              />
              <Route
                path="*"
                element={<PageNotFound />}
              />
            </Routes>
          </div>
        </div>
      </YearContext.Provider>
    </ErrorBoundary>
  );
}

export default App;
